@font-face {
  font-family: 'visuelt';
  src: url('../src/assets/fonts/visuelt_regular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'visuelt-black';
  src: url('../src/assets/fonts/visuelt_black.otf');
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'visuelt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none !important;
}

.MuiButton-label-158 {
  text-transform: none;
}

.sortable-handler {
  touch-action: none;
}

.MuiPopover-paper-195 {
  border: 1px solid black;
}
.MuiPopover-paper-195::before {
  content: '';
  position: absolute;
  right: 4px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent green transparent;
  z-index: 9998;
}

.MuiExpansionPanel-root:before {
  display: none;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
